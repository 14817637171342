
/**
 * Map views for all the devices
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface MapItem {
  status: "OK" | "WARNING" | "WAITING" | "ALERT";
  mainBoardId: string | null;
  kilometer: string;
  batteryVoltage: number;
  picture: string;
  width: number;
  height: number;
  prewarning: boolean;
  radio: boolean;
  position: {
    lat: number | null;
    lng: number | null;
  };
  charginCurrent: string;
  brightness: number;
}

import { Component, Prop, Mixins } from "vue-property-decorator";
import { MainProjectSection, MainProjectSectionDeviceView } from "@/model/MainProject/MainProjectSection";
import { mapGetters } from "vuex";
import MapHelper from "@/mixins/map/MapHelper";
import UserAccess from "@/model/User/UserAccess";
import { BatteryDevice } from "@/model/SingleDeviceModal";
import { DeviceProject } from "@/interface/DeviceInterface";

import Project from "@/model/Project";

@Component({
  name: "MainProjectMapView",

  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("MainProject", {
      GETTER_MAIN_DIRECTIONS: "GETTER_MAIN_DIRECTIONS"
    }),
    ...mapGetters({
      getAllDevices: "getAllDevices",
      GetActiveSubProjectsList: "GetActiveSubProjectsList"
    }),
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject",
      GetActiveSubProjectsList: "GetActiveSubProjectsList"
    })
  },
  methods: {}
})
export default class MainProjectMapView extends Mixins(MapHelper) {
  readonly GETTER_MAIN_DIRECTIONS!: Map<String, Array<MainProjectSection>>;
  readonly getUserAccess!: UserAccess;
  readonly CurrentProject!: Project;
  private GetActiveSubProjectsList!: Function;
  readonly getAllDevices!: Array<BatteryDevice>;

  showBatteries = false;

  /*----------  Props  ----------*/
  @Prop({ type: Object, required: true }) CURRENT_MAIN_PROJECT!: Record<string, string>;

  get mapSections(): Array<any> {
    const items: Array<any> = [];
    this.GETTER_MAIN_DIRECTIONS.forEach((value) => {
      value.forEach((item: MainProjectSection) => {
        const shouldBeVisible = item.visible || this.getUserAccess.Access.EDIT.project.sectionListTab.visibleSection;
        if (item.type.name === "info" || !shouldBeVisible) return;

        if (item.deviceOne.id && item.deviceOne.latitude !== 0 && item.deviceOne.longitude !== 0) {
          items.push(this.formatInformation(item.deviceOne, item.kilometer));
        }

        if (item.deviceTwo.id && item.deviceTwo.latitude !== 0 && item.deviceTwo.longitude !== 0) {
          items.push(this.formatInformation(item.deviceTwo, item.kilometer));
        }
      });
    });

    this.getBatteriesByProjectId(items, this.CurrentProject.id);

    return items;
  }

  private getBatteriesByProjectId(items: Array<any>, id: number) {
    let activeSubProjects = this.GetActiveSubProjectsList(id);
    if (activeSubProjects.length > 0) {
      activeSubProjects.forEach((subProject: any) => {
        this.getBatteriesByProjectId(items, subProject.id);
        if (this.getUserAccess.Access.VIEW.project.batteryList && this.showBatteries) {
          this.getAllDevices.forEach((device: any) => {
            if (
              device.type.name === "battery" &&
              device.projects.some((project: DeviceProject) => {
                return project.id.toString() === subProject.id.toString();
              })
            ) {
              let battery = this.formatBatteryInformation(device);
              items.push(battery);
            }
          });
        }
      });
    }
  }

  formatInformation(item: MainProjectSectionDeviceView, kilometer: string): MapItem {
    return {
      status: item.status,
      batteryVoltage: item.batteryVoltage,
      height: item.displayType?.height || 48,
      width: item.displayType?.width || 48,
      kilometer: kilometer,
      mainBoardId: item.id,
      picture: item.actualPicture,
      prewarning: item.prewarning,
      radio: item.radio,
      position: {
        lat: item.latitude ? item.latitude : null,
        lng: item.longitude ? item.longitude : null
      },
      brightness: item.brightness,
      charginCurrent: item.charging
    };
  }

  formatBatteryInformation(battery: any) {
    let sectionDetails = {
      device: "Battery",
      deviceId: battery.id,
      emnifyIccId: battery.emnifyIccId,
      position: {
        lat: battery.latitude ? battery.latitude : null,
        lng: battery.longitude ? battery.longitude : null
      },
      gpsLastUpdated: battery.gpsLastUpdated,
      serialNumber: battery.serialNumber,
      type: battery.type.name
    };
    return sectionDetails;
  }
}
